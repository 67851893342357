<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Products Module</h4>
        </v-col>
      </v-layout>
      <v-layout wrap class="mt-5">
        
        <v-col cols="12" md="4">
          <v-layout class="boxStat" style="border: 1px solid #72BD61;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Manufactures Products</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{manufacturersProducts.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="4">
          <v-layout class="boxStat" style="border: 1px solid #2776ED;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Distributors Products</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{distributorsProducts.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="4">
          <v-layout class="boxStat" style="border: 1px solid #61BDBD;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Retailers Products</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{retailersProducts.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg">
            <v-col cols="12">
              <v-layout wrap>
                <h5>All Products</h5>
              </v-layout>

              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="600px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Product Image
                          </th>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Price
                          </th>
                          <th class="text-left">
                            Qty
                          </th>
                          <th class="text-left">
                            Prescription
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in products" :key="product.productId">
                          <td>
                            <v-img height="80" :src="product.productImagePath" width="80" contain />
                          </td>
                          <td style="font-size:12px;">{{ product.name }}</td>
                          <td style="font-size:12px;">{{ product.price }}</td>
                          <td style="font-size:12px;">{{ product.quantity }}</td>
                          <td style="font-size:12px;">{{ product.needsPrescription }}</td>
                          <td style="font-size:12px;">{{ product.isActive }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
export default {
  name: "AdminProductsModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      systemSetting: {
        systemCurrency: "GH₵",
      },
      products:[],
      manufacturersProducts:[],
      distributorsProducts:[],
      retailersProducts:[],
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          this.products = response.data.data;
          response.data.data.forEach(element => {
            console.log(element);
              if(element.User.Role.name == 'Manufacturer'){
                  this.manufacturersProducts.push(element);
              }
              if(element.User.Role.name == 'Distributor'){
                  this.distributorsProducts.push(element);
              }
              if(element.User.Role.name == 'Retailer'){
                  this.retailersProducts.push(element);
              }
          });
        });
    },
  }
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}
</style>
